import React from "react"
import MessageIcon from "../icons/message.svg"

import AngleRight from "../icons/angle-right.svg"
import { Link } from "gatsby"

const SiteNavBar = () => {
  return (
    <div>
      <div className="navbar">
        <div className="content-wrapper navbar--wrapper">
          <a href="//owner.com" className="navbar--logo">
            Owner
          </a>
          <a className="navbar-button" href="//owner.com/support/">
            <span>Get Support</span>
            <MessageIcon className="button-icon" />
          </a>
        </div>
      </div>
      <div className="secondary-navbar">
        <div className="content-wrapper navbar--wrapper">
          <div className="secondary-navbar--left-pane">
            <h2 className="secondary-navbar--title">
              <Link to="/">Product Blog</Link>
            </h2>
            <p className="secondary-navbar--subtitle">
              New updates and improvements to Owner
            </p>
          </div>
          {/* <div>
            <a className="navbar-button" href="rss:localhost:8000/rss.xml">
              <span>Subscribe to updates</span>
              <AngleRight className="button-icon" />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default SiteNavBar
