import * as React from "react"
import SiteNavBar from "./SiteNavBar"
import Footer from "./footer"

const Layout = ({ location, title, children }) => {
  return (
    <div className="global-wrapper">
      <header className="sticky-header">
        <SiteNavBar title={title} />
      </header>
      <main>{children}</main>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

export default Layout
