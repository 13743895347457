import * as React from "react"

import OwnerLogo from "../icons/owner.svg"

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer content-wrapper">
        <div className="footer--text">
          <div className="footer--logo">
            <OwnerLogo className="footer--logo-img" />
            Owner
          </div>
          <p>Interested in working with us? Schedule a demo.</p>
        </div>
        <div className="footer--button-section">
          <a className="footer--button" href="//owner.com/">
            Get a Free Demo
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
